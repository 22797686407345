<template>
  <div>
    <div id="header" class="tabs-slider featured-program-groups" v-if="groups && groups.length > 0" ref="header" :class="{ 'sticky-tab2': isHeaderSticky }">
      <div class="chips">
        <div v-for="sosCategory in groups" :key="sosCategory.CourseGroupId" @click="updateSelectedsosCategory(sosCategory)" class="chips-inner">
          <div
            class="exam-tabs-head lowerHeadings"
            :class="{
              featuredProgramSubHead: selectedCourseGroupCategory.Id == sosCategory.CourseGroupId,
            }"
          >
            {{ sosCategory.CourseGroupDisplayName }}
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <skeleton-loader id="examCoursesLoader" :count="3" :showEmptyTemplate="true" :defaultLoadingProp="true">
        <template v-slot:default="{ isLoading }">
          <div class="row" v-if="groups.length && courseList.length">
            <div class="col-lg-4 col-md-6 mb60" v-for="(courseListItem, index) in courseList" :key="index">
              <courseCard :model="courseListItem" />
            </div>
          </div>
          <div class="text-center" v-else-if="isLoading && courseList.length == 0">
            <div class="img-cntr">
              <img src="/assets/images/banner/no-courses-avatar.svg" alt="keep shoping" width="200" height="200" class="mx-auto pb-4" />
            </div>
            <no-record-template></no-record-template>
          </div>
          <div class="load-more">
            <a v-if="canCoursesLoadMore && isLoading" id="btnLoadMore" @click="loadMore" href="javascript:void(0)" class>
              <span class="load-more">Load More</span>
            </a>
          </div>
        </template>
      </skeleton-loader>
    </div>
    <cartConfirmationModal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { exam as examStoreKeys, course as courseStoreKeys, classCourses as classCoursesStoreKeys } from "../../../store/keys";
import courseCard from "../../../components/courseCard.vue";
import { appConstants } from "../../../common/constants";
import cartConfirmationModal from "../cartConfirmationModal.vue";
import SsrCarousel from "vue-ssr-carousel";
import categoriesFilter from "../../../components/categoriesFilter";
export default {
  props: ["CategoryName", "selectedCategoryId", "groups"],

  // Client-side only
  metaInfo() {
    return {
      title: `Popular Programs | KIPS VIRTUAL`,
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  components: {
    courseCard,
    cartConfirmationModal,
    SsrCarousel,
    categoriesFilter,
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.hash) {
      var elm = this.$route.hash.slice(1);
      this.scrollToElement(elm);
    }
    // if (this.sosCategories(true).length > 0) {
    //   // var category = this.sosCategories(true).find((c) => c.CourseGroupName.toLowerCase() == item);
    //   this.updateSelectedsosCategory(this.sosCategories(true)[0]);
    // }
    let routeParams = this.$route.params;
    // this.getExamsGroups();
    this.updateSelectedsosCategory(this.groups[0]);
    window.addEventListener("scroll", () => {
      this.scrollY = Math.round(window.scrollY);
      if (this.$refs.header) {
        this.headerTop = document.getElementById("popularExamsSection").offsetTop;
        if (this.scrollY > this.headerTop + 100) {
          this.isHeaderSticky = true;
          document.body.classList.add("exam-body");
        } else {
          this.isHeaderSticky = false;
          document.body.classList.remove("exam-body");
        }
      }
    });
  },
  data() {
    return {
      selectedCourseGroupCategory: {
        Id: 0,
        Name: "",
      },
      isHeaderSticky: false,
      coursePaging: {
        pageNumber: 1,
        pageSize: 12,
      },
      showRecordsNotFound: false,
    };
  },
  asyncData({ store, route }) {},
  computed: {
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(classCoursesStoreKeys.namespace, classCoursesStoreKeys.getters),
  },
  // Server-side only
  async serverPrefetch() {
    this.reset_ClassCourses();
    this.updateSelectedsosCategory(this.groups[0]);
    // return this.getExamsGroups();
  },
  watch: {
    selectedCategoryId(oldVal, newVal) {
      this.updateSelectedsosCategory(this.groups[0]);
    },
  },
  methods: {
    ...mapActions(examStoreKeys.namespace, examStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(classCoursesStoreKeys.namespace, classCoursesStoreKeys.actions),
    getExamsGroups() {
      return this.get_Exams_Groups({
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
      })
        .then((response) => {
          if (this.sosCategoriesPopularExams(true).length > 0) {
            // var category = this.sosCategories(true).find((c) => c.CourseGroupName.toLowerCase() == item);
            this.updateSelectedsosCategory(this.sosCategoriesPopularExams(true)[0]);
          }
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    loadMore() {
      this.coursePaging.pageNumber++;
      this.getClassCourses(appConstants.enums.pagingMode.loadMore);
    },
    updateSelectedsosCategory(sosCategory) {
      this.selectedCourseGroupCategory.Id = sosCategory.CourseGroupId;
      this.selectedCourseGroupCategory.Name = sosCategory.CourseGroupName;
      this.coursePaging.pageNumber = 1;
      //this.resetClassCourses();
      this.getClassCourses();
    },
    // resetClassCourses() {
    // this.reset_ClassCourses().then((response) => {
    // this.coursePaging.pageNumber = 1;
    // this.getClassCourses();
    // });
    // },
    getClassCourses(pagingMode) {
      this.get_ClassCourses({
        data: {
          CourseListFilter: 1,
          CourseGroupId: this.selectedCourseGroupCategory.Id,
          ProgramCategoryId: this.selectedCategoryId,
          ApplyDefaultTagFilter: false,
          PageNumber: this.coursePaging.pageNumber,
          PageSize: this.coursePaging.pageSize,
        },
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
        pagingMode: pagingMode,
      })
        .then((response) => {
          this.showRecordsNotFound = true;
          this.coursePaging.pageNumber = response.PageNumber;
        })
        .catch((ex) => {
          this.showRecordsNotFound = false;
          this.$alertError(ex.message);
        });
    },
  },
};
</script>

<style scoped>
.loadmore-separator {
  background: #ccc;
  height: 1px;
  width: 70%;
  margin-top: -17px;
  margin: -17px auto 0 auto;
}

.load-more {
  text-align: center;
  cursor: pointer;
}

.load-more i {
  background: #fff;
  padding: 9px 10px;
  border-radius: 50%;
  font-size: 17px;
  box-shadow: 0 0 10px 0px #d8d8d8;
}
</style>
