<template>
  <div>
    <div>
      <page-header heading="Featured Programs" desc="Click on the tabs below to select your respective program."></page-header>
    </div>
    <section class="popular-exams-section online-course-details exams-grid" id="popularExamsSection">
      <!-- :class="{ 'sticky-tab': isHeaderSticky } -->
      <div id="header" class="tabs-slider featured-program-categories" v-if="programCategories.length">
        <div ref="header" :class="{ 'sticky-tab': isHeaderSticky }">
          <SsrCarousel :slides-per-page="null" :responsive="[{ maxWidth: 425, slidesPerPage: 2 }]">
            <div
              v-for="(category, index) in getCategories"
              :key="index"
              @click="goto(category)"
              class="exam-tabs-head upperHeadings"
              :class="{
                featuredProgramMainHead: selectedCategory == category.ProgramCategory.ProgramCategoryId,
              }"
            >
              {{ category.ProgramCategory.Category }}
            </div>
          </SsrCarousel>
        </div>
        <div v-if="getCategoryGroups && getCategoryGroups.length > 0">
          <popularExams :selectedCategoryId="selectedCategory" :groups="getCategoryGroups" />
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { programCategories as programCategoriesStoreKeys } from "../store/keys";
import SsrCarousel from "vue-ssr-carousel";
import popularExams from "../views/course/exam/popularExams";
import pageHeader from "./pageHeader";
import Footer from "../layout/landing/appFooter";
export default {
  components: {
    SsrCarousel,
    popularExams,
    pageHeader,
    Footer,
  },
  data() {
    return {
      selectedCategory: null,
      isHeaderSticky: false,
    };
  },
  mounted() {
    this.getProgramCategories();
    window.addEventListener("scroll", () => {
      this.scrollY = Math.round(window.scrollY);
      if (this.$refs.header) {
        this.headerTop = document.getElementById("popularExamsSection").offsetTop;
        if (this.scrollY > this.headerTop + 100) {
          this.isHeaderSticky = true;
          document.body.classList.add("exam-body");
        } else {
          this.isHeaderSticky = false;
          document.body.classList.remove("exam-body");
        }
      }
    });
  },
  computed: {
    ...mapGetters(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.getters),
    getCategoryGroups() {
      const filteredCategories = this.programCategories.filter((category) => {
        return category.ProgramCategory.ProgramCategoryId == this.selectedCategory;
      });
      const uniqueGroups = [
        ...new Set(
          filteredCategories
            .filter((obj) => {
              if (obj.Group) {
                return obj;
              }
            })
            .map((obj) => obj.Group.CourseGroupId)
        ),
      ]
        .map((id) => {
          return filteredCategories.find((obj) => obj.Group && obj.Group.CourseGroupId === id);
        })
        .map((category) => {
          return category.Group;
        });
      let filter = [{ CourseGroupDisplayName: "All", CourseGroupId: undefined }];
      if (uniqueGroups.length) {
        return uniqueGroups.length === 1 ? uniqueGroups : [...filter, ...uniqueGroups];
      } else return [];
    },
  },
  methods: {
    ...mapActions(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.actions),
    getProgramCategories() {
      this.get_ProgramCategories({
        config: {
          loader: {
            show: true,
            id: "boardLoader",
          },
        },
      }).then(() => {
        this.selectedCategory = this.programCategories[0].ProgramCategory.ProgramCategoryId;
        // this.selectedCategory = 1;
      });
    },
    goto(category) {
      this.selectedCategory = category.ProgramCategory.ProgramCategoryId;
    },
  },
};
</script>

<style></style>
