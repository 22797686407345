<template>
  <div class="registration-page">
    <!-- <page-header heading="How can we help you?" desc="A quick way to know about KIPS virtual" /> -->
    <header class="registration-header ph-5">
      <h1 class="text-center">Registration Form</h1>
    </header>
    <div class="registration-form-wrap position-relative">
      <img class="position-absolute registration-bg-1 d-none d-md-block" src="/assets/images/Group 12918.png" alt="" />
      <img class="position-absolute registration-bg-2 d-none d-md-block"
        src="/assets/images/banner/circle-pattern-2.svg " alt="" />
      <div class="container registration-form-container">
        <div class="form">
          <div class="personal-info form-line">
            <label class="registration-form-heading">Personal Information</label>
            <div class="row">
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.name.$error }" placeholder="Name"
                    v-model="model.name"></b-form-input>
                  <div class="invalid-feedback" v-if="!$v.model.name.required">Field is required</div>
                  <span v-if="!$v.model.name.isAlpha && $v.model.name.$error"
                    :class="{ 'invalid-error': $v.model.name.$error }"> Should be an Alphabet </span>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.cnic.$error }" placeholder="B.Form / CNIC (without dashes)"
                    v-model="model.cnic"></b-form-input>

                  <div v-if="!$v.model.cnic.integer && $v.model.cnic.$error"
                    :class="{ 'invalid-error  ': $v.model.cnic.$error }">Should be a number</div>
                  <div v-if="!$v.model.cnic.minLength && $v.model.cnic.$error"
                    :class="{ 'invalid-error': $v.model.cnic.$error }">Minimum 9 digits</div>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" :class="{ 'invalid-input': $v.model.whatsapp.$error }"
                    class="registration-input-field" placeholder="Whatsapp No. (923XXXXXXXXX)"
                    v-model="model.whatsapp"></b-form-input>

                  <div v-if="!$v.model.whatsapp.minLength && $v.model.whatsapp.$error"
                    :class="{ 'invalid-error  ': $v.model.whatsapp.$error }">Minimum 7 digits</div>
                  <div v-if="!$v.model.whatsapp.integer && $v.model.whatsapp.$error"
                    :class="{ 'invalid-error': $v.model.whatsapp.$error }">Should be a number</div>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input type="email" id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.email.$error }" placeholder="Email"
                    v-model="model.email"></b-form-input>

                  <span v-if="!$v.model.email.email && $v.model.email.$error"
                    :class="{ 'invalid-error': $v.model.email.$error }"> Email not correct </span>
                </div>
              </div>
              <div class="col-sm-12 mt-4 radio-btns">
                <div role="group">
                  <span>
                    <input class="form-check-input custom-radio" type="radio" name="pakistan" id="pakistan"
                      value="Pakistan" v-model="model.citizen" :class="{ 'invalid-input': $v.model.citizen.$error }" />
                    <label class="form-check-label" for="pakistan"> Pakistan </label>
                  </span>

                  <span class="ml-5">
                    <input class="form-check-input pl-4 custom-radio" type="radio" name="overseas" id="overseas"
                      value="overseas" v-model="model.citizen" />
                    <label class="form-check-label" for="overseas" @click="model.country = ''"> Overseas </label>
                  </span>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 mt-4">
                <div class="city-drop-down" role="group" v-if="model.citizen == 'Pakistan'">
                  <v-select :options="cities" placeholder="Select City" label="title"
                    :class="{ 'invalid-input': !model.country && showCityError }" style="border-radius: 5px"
                    @input="onSelect"></v-select>
                </div>
                <div role="group" v-else>
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': !model.country && showCityError }" placeholder="Enter your country"
                    v-model="model.country" @input="onSelect"></b-form-input>
                  <!-- <span v-if="!model.country && showCityError" :class="{ 'invalid-error': showCityError }">
                    City not selected
                  </span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="academic-info form-line">
            <label class="registration-form-heading">Parents / Guardian Information</label>
            <div class="row">
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.fatherName.$error }" placeholder="Name"
                    v-model="model.fatherName"></b-form-input>
                  <span v-if="!$v.model.fatherName.isAlpha && $v.model.fatherName.$error"
                    :class="{ 'invalid-error': $v.model.fatherName.$error }"> Should be an Alphabet </span>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.fatherCnic.$error }" placeholder="CNIC (without dashes)"
                    v-model="model.fatherCnic"></b-form-input>
                  <div v-if="!$v.model.fatherCnic.integer && $v.model.fatherCnic.$error"
                    :class="{ 'invalid-error  ': $v.model.fatherCnic.$error }">Should be a number</div>
                  <div v-if="!$v.model.fatherCnic.minLength && $v.model.fatherCnic.$error"
                    :class="{ 'invalid-error': $v.model.fatherCnic.$error }">Minimum 9 digits</div>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.fatherCell.$error }" placeholder="Cell No. (923XXXXXXXXX)"
                    v-model="model.fatherCell"></b-form-input>
                  <div v-if="!$v.model.fatherCell.integer && $v.model.fatherCell.$error"
                    :class="{ 'invalid-error  ': $v.model.fatherCell.$error }">Should be a number</div>
                  <div v-if="!$v.model.fatherCell.minLength && $v.model.fatherCell.$error"
                    :class="{ 'invalid-error ': $v.model.fatherCell.$error }">Minimum 7 digits</div>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-select v-model="model.fatherIncome" class="registration-select-field"
                    :class="{ 'invalid-input': $v.model.fatherIncome.$error }" size="lg">
                    <b-form-select-option class="mt-3" value="" disabled selected hidden>Select Annual
                      Income</b-form-select-option>
                    <b-form-select-option class="mt-3" value="Less than 15 lacs ">Less than 15 lacs
                    </b-form-select-option>
                    <b-form-select-option class="mt-3" value="15- 25 lacs ">15- 25 lacs </b-form-select-option>
                    <b-form-select-option class="mt-3" value="25-35 lacs ">25-35 lacs </b-form-select-option>
                    <b-form-select-option class="mt-3" value="Above 40 lacs  ">Above 40 lacs </b-form-select-option>
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
          <div class="academic-info">
            <label class="registration-form-heading">Academic Information</label>
            <div class="row">
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-select v-model="model.lastExam" @change="lastExamChange()" class="registration-select-field"
                    :class="{ 'invalid-input': $v.model.lastExam.$error }" size="lg">
                    <b-form-select-option class="mt-3" value="" disabled selected hidden>Select Last
                      Exam</b-form-select-option>
                    <b-form-select-option class="mt-3" value="11th">11th</b-form-select-option>
                    <b-form-select-option class="mt-3" value="12th">12th</b-form-select-option>
                    <b-form-select-option class="mt-3" value="A Levels">A Levels</b-form-select-option>
                    <b-form-select-option class="mt-3" value="AS Levels">AS Levels</b-form-select-option>
                    <b-form-select-option class="mt-3" value="Other">Other</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div class="col-sm-6 mt-3" v-if="model.lastExam == 'Other'">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.otherLastExam.$error }" placeholder="Your Last Exam"
                    v-model="model.otherLastExam"></b-form-input>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-select v-model="model.passingYear" class="registration-select-field"
                    :class="{ 'invalid-input': $v.model.passingYear.$error }" size="lg">
                    <b-form-select-option class="mandat" value="" disabled selected hidden>Select Passing
                      Year</b-form-select-option>
                    <b-form-select-option class="mandat" value="2024">2024</b-form-select-option>
                    <b-form-select-option class="mandat" value="2023">2023</b-form-select-option>
                    <b-form-select-option class="mandat" value="2022">2022</b-form-select-option>
                    <b-form-select-option class="mandat" value="2021">2021</b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field" placeholder="Marks Obtained"
                    v-model="model.marksObtained"
                    :class="{ 'invalid-input': $v.model.marksObtained.$error }"></b-form-input>
                  <div v-if="!$v.model.marksObtained.integer && $v.model.marksObtained.$error"
                    :class="{ 'invalid-error': $v.model.marksObtained.$error }">Should be a number</div>
                  <div v-if="!$v.model.marksObtained.maxValue && $v.model.marksObtained.$error"
                    :class="{ 'invalid-error': $v.model.marksObtained.$error }">Maximum 4 digits</div>
                  <div v-if="!$v.model.marksObtained.between && $v.model.marksObtained.$error"
                    :class="{ 'invalid-error': $v.model.marksObtained.$error }">Obtained Marks should be less than total
                    marks</div>
                </div>
              </div>
              <div class="col-sm-6 mt-3">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field" placeholder="Total Marks"
                    v-model="model.totalMarks" :class="{ 'invalid-input': $v.model.totalMarks.$error }"></b-form-input>
                  <div v-if="!$v.model.totalMarks.integer && $v.model.totalMarks.$error"
                    :class="{ 'invalid-error': $v.model.totalMarks.$error }">Should be a number</div>
                  <div v-if="!$v.model.totalMarks.maxValue && $v.model.totalMarks.$error"
                    :class="{ 'invalid-error': $v.model.totalMarks.$error }">Maximum 4 digits</div>
                </div>
              </div>

              <div class="col-sm-6 mt-3" v-if="model.lastExam !== 'Other'">
                <div role="group">
                  <b-form-select v-model="model.board" class="registration-select-field"
                    :class="{ 'invalid-input': $v.model.board.$error }" size="lg">
                    <b-form-select-option class="mandat" value="" disabled selected hidden>Select
                      Board</b-form-select-option>
                    <b-form-select-option v-if="model.lastExam == '11th' || model.lastExam == '12th'" class="mt-3"
                      value="Federal Board">Federal Board </b-form-select-option>
                    <b-form-select-option class="mt-3" v-if="model.lastExam == '11th' || model.lastExam == '12th'"
                      value="Punjab Board">Punjab Board</b-form-select-option>
                    <b-form-select-option class="mt-3" v-if="model.lastExam == '11th' || model.lastExam == '12th'"
                      value="KPK Board ">KPK Board </b-form-select-option>
                    <b-form-select-option class="mt-3" v-if="model.lastExam == '11th' || model.lastExam == '12th'"
                      value="Sindh Board">Sindh Board</b-form-select-option>
                    <b-form-select-option class="mt-3" v-if="model.lastExam == '11th' || model.lastExam == '12th'"
                      value="Baluchistan Board">Baluchistan Board</b-form-select-option>
                    <b-form-select-option class="mt-3" v-if="model.lastExam == '11th' || model.lastExam == '12th'"
                      value="AJK Board">AJK Board</b-form-select-option>
                    <b-form-select-option class="mt-3" v-if="model.lastExam == '11th' || model.lastExam == '12th'"
                      value="Agha Khan Board">Agha Khan Board</b-form-select-option>
                    <b-form-select-option class="mt-3"
                      v-if="model.lastExam == 'A Levels' || model.lastExam == 'AS Levels'"
                      value="Cambridge International Examinations">Cambridge International Examinations
                    </b-form-select-option>
                    <b-form-select-option class="mt-3"
                      v-if="model.lastExam == 'A Levels' || model.lastExam == 'AS Levels'"
                      value="Pearson Edexcel">Pearson Edexcel </b-form-select-option>
                    <b-form-select-option class="mt-3"
                      v-if="model.lastExam == 'A Levels' || model.lastExam == 'AS Levels' || model.lastExam == 'Other'"
                      value="Other">Other </b-form-select-option>
                  </b-form-select>
                </div>
              </div>
              <div class="col-sm-6 mt-3" v-if="model.lastExam == 'Other' || model.board == 'Other'">
                <div role="group">
                  <b-form-input id="input-formatter" class="registration-input-field"
                    :class="{ 'invalid-input': $v.model.otherBoard.$error }" placeholder="Your Board"
                    v-model="model.otherBoard"></b-form-input>
                </div>
              </div>
              <div v-else></div>
            </div>
          </div>

          <div class="submit-section">
            <button class="registration-submit" @click="makeRegistration" :disabled="submitStatus">Submit <span
                class="spinner-border" v-if="recaptchaLoader"></span></button>
            <div class="recaptcha-message">
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">Privacy Policy</a>
              and
              <a href="https://policies.google.com/terms">Terms of Service</a>
              apply.
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-alert class="message-box-app d-flex align-items-center mt-0 mt-md-2" :show="dismissCountDown" dismissible
      variant="danger" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">
      <i class="fas fa-times-circle"></i>
      <div class="alert-cntr">
        <span class="mb0"> ERROR </span>
        <div v-html="this.Message"></div>
      </div>
    </b-alert>
    <appFooter />

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import footer from "../layout/landing/appFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import { required, email, between, requiredIf, integer, minLength, maxValue } from "vuelidate/lib/validators";
import { load } from "recaptcha-v3";
import { cart as cartStoreKeys } from "../store/keys";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import { cities } from "./cities";
export default {
  components: {
    appFooter: footer,
    pageHeader,
    "v-select": vSelect,
  },
  computed: {
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
    nameState() {
      return this.name.length > 2 ? true : false;
    },
    getTotalMarks() {
      const result = this.model.totalMarks == "" ? 0 : Number(this.model.totalMarks);
      return result;
    },
  },
  metaInfo: {
    title: "Registration | KIPS VIRTUAL",

    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  asyncData({ store, route }) { },
  created() {
    if (process.env.VUE_ENV === "server") {
      this.reset_Cart();
    }
    if (typeof window === "undefined") {
      this.reset_Cart();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if (process.env.VUE_ENV === "server") {
      this.reset_Cart();
    }
    if (typeof window === "undefined") {
      this.reset_Cart();
    }
  },
  data() {
    return {
      Message: "Please fill all fields",
      dismissSecs: 1,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      cityError: true,
      showCityError: false,
      selectedCity: "",
      name: "",
      submitStatus: false,
      recaptchaLoader: false,
      cities: cities,
      selected: null,
      options: [
        { value: null, text: "Father Annual Income" },
        { value: 1, text: "Less than 15 Lac" },
        { value: 2, text: "15-25" },
        { value: 3, text: "25-35" },
        { value: 4, text: "Above 40 Lacs" },
      ],
      model: {
        name: "",
        cnic: "",
        whatsapp: "",
        email: "",
        citizen: "Pakistan",
        country: "",
        fatherName: "",
        fatherCnic: "",
        fatherCell: "",
        fatherIncome: "",
        lastExam: "",
        otherLastExam: "",
        passingYear: "",
        marksObtained: "",
        totalMarks: "",
        board: "",
        otherBoard: "",
      },
    };
  },
  validations() {
    const isAlpha = (value) => {
      const regex = /^[A-Za-z\s]*$/; // Regex to match strings with optional spaces
      return typeof value === "string" && regex.test(value);
    };
    return {
      model: {
        name: { required, isAlpha },
        cnic: { required, integer, minLength: minLength(9) },
        whatsapp: { required, integer, minLength: minLength(7) },
        email: { required, email },
        citizen: { required },
        country: { required },
        fatherName: { required, isAlpha },
        fatherCnic: { required, integer, minLength: minLength(9) },
        fatherCell: { required, integer, minLength: minLength(7) },
        fatherIncome: { required },
        lastExam: { required },
        passingYear: { required },
        marksObtained: {
          required,
          integer,
          maxValue: maxValue(9999),
          between: between(0, this.getTotalMarks),
        },
        totalMarks: { required, integer, maxValue: maxValue(9999) },
        board: {
          required: requiredIf(() => this.model.lastExam !== "Other"),
        },
        otherBoard: {
          required: requiredIf(() => this.model.lastExam === "Other" || this.model.board === "Other"),
        },
        otherLastExam: {
          required: requiredIf(() => this.model.lastExam === "Other"),
        },
      },
    };
  },
  destroyed() {
    this.reset_Cart();
  },
  methods: {
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    validateSelection(selection) {
      this.model.country = selection.name;
    },
    lastExamChange() {
      if (this.model.lastExam == "11th" || this.model.lastExam == "12th" || this.model.lastExam == "AS Levels" || this.model.lastExam == "A Levels") {
        this.model.board = "";
        this.model.otherBoard = "";
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    onSelect(selectedCity) {
      this.model.country = selectedCity;
      this.cityError = false;
    },
    async makeRegistration() {
      this.$v.$touch();
      if (this.cityError) {
        this.showCityError = true;
        this.showAlert();
        // return
      }
      if (this.$v.$invalid) {
        this.showAlert();
      } else {
        this.recaptchaLoader = true;
        this.submitStatus = true;
        const modelData = {
          Name: this.model.name,
          CellNumber: this.model.whatsapp,
          Cnic: this.model.cnic,
          Email: this.model.email,
          Country: this.model.citizen,
          City: this.model.country,
          GuardianName: this.model.fatherName,
          GuardianCellNumber: this.model.fatherCell,
          GuardianCnic: this.model.fatherCnic,
          GuardianAnnualIncome: this.model.fatherIncome,
          LastExam: this.model.lastExam == "Other" ? this.model.otherLastExam : this.model.lastExam,
          PassingYear: this.model.passingYear,
          ObtainedMarks: this.model.marksObtained,
          TotalMarks: this.model.totalMarks,
          Board: this.model.board == "Other" || this.model.board == "" ? this.model.otherBoard : this.model.board,
          Sosid: this.cart[0].SOSId,
          ProgramGroup: this.cart[0].CourseGroupId.toString(),
        };
        load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
          autoHideBadge: true,
        })
          .then((recaptcha) => {
            recaptcha
              .execute("login")
              .then((token) => {
                if (token) {
                  this.register({
                    data: modelData,
                    config: {
                      loader: {
                        show: true,
                      },
                    },
                    token: token,
                  })
                    .then(() => {
                      this.check_Out();
                      this.recaptchaLoader = false;
                      this.submitStatus = false;
                    })
                    .catch((er) => {
                      this.recaptchaLoader = false;
                      this.submitStatus = false;
                    });
                }
              })
              .catch((err) => {
                console.log(err);
                this.recaptchaLoader = false;
                this.submitStatus = false;
              });
          })
          .catch((err) => {
            this.recaptchaLoader = false;
            this.submitStatus = false;
          });
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
